<template>
  <div>
    <v-card>
      <v-card-title class="success white--text text-h5">
        Учителя
        <v-spacer></v-spacer>
        <v-btn
          v-if="$store.getters.profile.user_access_level == 'training_officer'"
          @click="form_dialog = true"
          outlined
          color="white"
          >Новый учитель</v-btn
        >
      </v-card-title>
      <v-row class="pa-4" justify="space-between">
        <v-col sm="12" md="12" lg="5" xl="5" cols="12">
          <v-treeview
            :active.sync="active"
            :items="items"
            :open.sync="open"
            activatable
            color="success"
            open-on-click
            transition
          >
            <template v-slot:prepend="{ item }">
              <v-avatar size="32" v-if="!item.children">
                <v-img :src="api + item.user.image"> </v-img>
              </v-avatar>
            </template>
          </v-treeview>
        </v-col>

        <v-divider vertical></v-divider>

        <v-col sm="12" md="12" lg="7" xl="7" cols="12" class="text-center">
          <v-scroll-y-transition mode="out-in">
            <div
              v-if="!selected"
              class="text-h6 grey--text text--lighten-1 font-weight-light"
              style="align-self: center"
            >
              Выберите учителя
            </div>
            <v-card
              v-else
              :key="selected.id"
              class="pt-6 mx-auto"
              flat
              max-width="400"
            >
              <v-card-text>
                <v-avatar size="120">
                  <v-img :src="api + selected.user.image"></v-img>
                </v-avatar>
                <h3 class="text-h5 mb-2">
                  {{ selected.user.first_name }} {{ selected.user.last_name }}
                </h3>
                <div class="blue--text mb-2">
                  <a
                    style="text-decoration: none"
                    :href="'mailto:' + selected.user.email"
                    >{{ selected.user.email }}</a
                  >
                </div>
                <div class="blue--text subheading font-weight-bold">
                  <a
                    style="text-decoration: none"
                    :href="'tel:+' + selected.user.phone"
                    >+{{ selected.user.phone }}</a
                  >
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-text>
                <div class="d-block">
                  <div class="mr-4 mb-2 text-left d-inline font-weight-bold">
                    Группы:
                  </div>
                  <v-chip
                    v-for="group in selected.groups"
                    v-bind:key="group.id"
                    color="success"
                    label
                    outlined
                    small
                    class="mr-1 mt-1 d-inline"
                    link
                    :to="{ name: 'GroupDetails', params: { gid: group.id } }"
                    >{{ group.number }}</v-chip
                  >
                </div>
                <br /><br />
                <div class="d-block">
                  <div class="mr-4 text-left mb-2 d-inline font-weight-bold">
                    Учебный план:
                  </div>
                  <v-btn
                    @click="SyllabusOpen(selected)"
                    class="d-inline"
                    small
                    color="success"
                    >открыть</v-btn
                  >
                </div>
              </v-card-text>
            </v-card>
          </v-scroll-y-transition>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog max-width="540px" v-model="form_dialog">
      <v-card>
        <v-card-title>
          Добавить учителя
          <v-spacer></v-spacer>
          <v-icon @click="form_dialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="form.first_name"
                  label="Имя"
                  :error-messages="FirstNameErrors"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="form.middle_name"
                  label="Отчество"
                  :error-messages="MiddleNameErrors"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="form.last_name"
                  :error-messages="LastNameErrors"
                  label="Фамилия"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="form.email"
                  :error-messages="EmailErrors"
                  label="Email"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  prepend-icon="mdi-plus"
                  v-model="form.phone"
                  :error-messages="PhoneErrors"
                  label="Телефонный номер"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-select
                  v-model="course"
                  :items="courses"
                  item-text="name"
                  :error-messages="CourseErrors"
                  item-value="id"
                  label="Выбрать курс"
                  return-object
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="form.password"
                  type="password"
                  label="Пароль"
                  :error-messages="PasswordErrors"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="password_confirm"
                  type="password"
                  :error-messages="PasswordConfirmErrors"
                  label="Подтвердите пароль"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="save"> Сохранить </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
      absolute
      right
      top
      color="green"
      elevation="6"
      multi-line
      tile
      class="snackbar"
      transition="slide-x-reverse-transition"
    >
      Учитель успешно добавлен
    </v-snackbar>
  </div>
</template>

<script>
import { TEACHER_GET, TEACHER_POST } from "@/store/actions/teacher";
import { GROUP_GET } from "@/store/actions/group";
import { COURSE_GET } from "@/store/actions/other";
import { USER_REGISTRATION } from "@/store/actions/users";
import { required, sameAs, minLength, email } from "vuelidate/lib/validators";
import { Check_email, phone } from "@/utils/validators";

export default {
  data() {
    return {
      teachers: [],
      courses: [],
      form_dialog: false,
      snackbar: false,
      active: [],
      avatar: null,
      open: [],
      api: process.env.VUE_APP_API_URL.slice(0, -1),
      form: {
        first_name: "",
        last_name: "",
        middle_name: "",
        password: "",
        phone: "",
        email: "",
        user_access_level: "teacher",
      },
      password_confirm: "",
      course: "",
    };
  },
  methods: {
    async GetTeachers() {
      let teachers = [];
      let resp = await this.$dispatch(TEACHER_GET);
      teachers = resp.result;
      let resp2 = await this.$dispatch(GROUP_GET);
      for (let t in teachers) {
        teachers[t].groups = [];
        for (let g in resp2.result) {
          if (teachers[t].id == resp2.result[g].teacher.id) {
            teachers[t].groups.push(resp2.result[g]);
          }
        }
      }
      this.teachers = teachers;
    },
    async LoadCourses() {
      let resp = await this.$dispatch(COURSE_GET);
      if (resp.success) {
        this.courses = resp.result;
      } else {
        alert(resp);
      }
    },
    SyllabusOpen(teacher) {
      this.$router.push({ name: "Syllabus", params: { id: teacher.id } });
    },
    async save() {
      this.$v.form.$touch();
      this.$v.course.$touch();
      this.$v.password_confirm.$touch();
      if (
        !this.$v.form.$invalid &&
        !this.$v.course.$invalid &&
        !this.$v.password_confirm.$invalid
      ) {
        let resp = await this.$dispatch(USER_REGISTRATION, this.form);
        if (resp.success) {
          let resp2 = await this.$dispatch(TEACHER_POST, {
            user: resp.result.id,
            direction: this.course.id,
          });
          if (resp2.success) {
            this.GetTeachers();
            this.form_dialog = false;
            this.snackbar = true;
          }
        } else {
          alert(resp.result);
        }
      }
    },
  },
  validations() {
    return {
      form: {
        first_name: { required },
        last_name: { required },
        middle_name: { required },
        password: { required, min: minLength(6) },
        phone: { required, phone },
        email: { required, Check_email, email },
      },
      password_confirm: {
        required,
        sameAsPassword: sameAs(function () {
          return this.form.password;
        }),
      },
      course: { required },
    };
  },
  computed: {
    FirstNameErrors() {
      const errors = [];
      if (!this.$v.form.first_name.$dirty) return errors;
      !this.$v.form.first_name.required &&
        errors.push("Имя обязательно");
      return errors;
    },
    LastNameErrors() {
      const errors = [];
      if (!this.$v.form.last_name.$dirty) return errors;
      !this.$v.form.last_name.required && errors.push("Фамилия обязательна");
      return errors;
    },
    MiddleNameErrors() {
      const errors = [];
      if (!this.$v.form.middle_name.$dirty) return errors;
      !this.$v.form.middle_name.required &&
        errors.push("Требуется отчество");
      return errors;
    },
    PasswordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.required && errors.push("Необходим пароль");
      !this.$v.form.password.minLength &&
        errors.push("Пароль должен содержать не менее 6 символов.");
      return errors;
    },
    PasswordConfirmErrors() {
      const errors = [];
      if (!this.$v.password_confirm.$dirty) return errors;
      !this.$v.password_confirm.sameAsPassword &&
        errors.push("Подтверждение пароля не совпадает");
      !this.$v.password_confirm.required &&
        errors.push("Требуется подтверждение пароля");
      return errors;
    },
    EmailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      !this.$v.form.email.email && errors.push("Должен быть действующий email");
      !this.$v.form.email.required && errors.push("Электронная почта обязательна");
      !this.$v.form.email.Check_email &&
        errors.push("Этот адрес электронной почты уже существует.");
      return errors;
    },
    PhoneErrors() {
      const errors = [];
      if (!this.$v.form.phone.$dirty) return errors;
      !this.$v.form.phone.required && errors.push("Номер телефона обязателен");
      !this.$v.form.phone.phone && errors.push("Номер телефона недействителен.");
      return errors;
    },
    CourseErrors() {
      const errors = [];
      if (!this.$v.course.$dirty) return errors;
      !this.$v.course.required && errors.push("Курс обязателен");
      return errors;
    },
    items() {
      let teachers = this.teachers;
      let items = [];

      for (let t in teachers) {
        teachers[t].name =
          this.teachers[t].user.first_name +
          " " +
          this.teachers[t].user.last_name;
      }
      for (let c in this.courses) {
        let item = {
          name: this.courses[c].name,
          children: [],
        };
        for (let t in teachers) {
          if (this.courses[c].id == teachers[t].direction.id) {
            item.children.push(teachers[t]);
          }
        }
        items.push(item);
        item = {};
      }
      return items;
    },
    selected() {
      if (!this.active.length) return undefined;

      const id = this.active[0];

      return this.teachers.find((teacher) => teacher.id === id);
    },
  },
  created() {
    this.GetTeachers();
    this.LoadCourses();
  },
};
</script>

<style scoped>
.p-0 {
  padding: 0 !important;
}
.d-block {
  display: inline-block !important;
}
</style>